<template>
  <div class="detailsWrap">
    <!-- <p style="border-bottom: 1px solid #ccc; padding-bottom: 10px">
      <router-link to="/warning-center">
        <a-icon type="rollback" /> 回到告警中心
      </router-link>
    </p> -->
    <div>
      <span class="detailsTitle">编辑规则</span>
      <span style="cursor: pointer" @click="visible = false"
        ><a-icon type="edit"
      /></span>
      <div class="detailsList">
        <ul style="margin: 0">
          <li style="display: flex">
            <span class="rulesTitle">规则名称：</span>
            <span v-if="visible">{{ ruleDetails.alarmRuleName }}</span>
            <a-input v-else v-model="form.name" style="width: 220px" />
          </li>
          <li>
            <span class="rulesTitle">告警级别：</span>
            <span v-if="visible">{{
              ruleDetails.level | warningLevelFilter
            }}</span>
            <a-select
              v-else
              v-model="form.level"
              style="width: 220px"
              placeholder="请选择告警级别"
            >
              <a-select-option value="2"> 紧急 </a-select-option>
              <a-select-option value="1"> 严重 </a-select-option>
              <a-select-option value="0"> 普通 </a-select-option>
            </a-select>
          </li>
          <li>
            <span class="rulesTitle">告警类型：</span>
            <span>{{ ruleDetails.type === 1 ? "设备告警" : "" }}</span>
          </li>
        </ul>
      </div>
      <div class="rulesDes">
        <span class="rulesTitle">规则描述: </span>
        <span v-if="visible">{{ ruleDetails.remark }}</span>
        <a-textarea v-else v-model="form.desc" style="flex: 1; resize: none" />
      </div>
      <p v-show="!visible" class="rulesBtn">
        <a-button style="margin-right: 20px" @click="visible = true">
          取消
        </a-button>
        <a-button type="primary" @click="onSure"> 确认 </a-button>
      </p>
    </div>
    <div>
      <p class="rulesCon">
        <span style="font-size: 16px; font-weight: bold">规则内容</span>
        <a-button type="primary" @click="rulesDialog = true">
          编辑规则内容
        </a-button>
      </p>
      <div style="border: 1px solid #333">
        <ul style="margin: 0">
          <li>
            <p class="tableTitle">
              <span class="tableItem" style="text-align: left; border: none"
                >触发条件</span
              >
              <span
                >限制：{{
                  ruleInfo.perfectMatchMode === 0
                    ? "满足任意条件执行"
                    : "满足全部条件执行"
                }}</span
              >
            </p>
            <p
              v-for="(item, index) in ruleInfo.conditions"
              :key="item.id"
              class="tableList"
              style="border-bottom: 1px solid #333"
            >
              <span class="tableItem">条件{{ index + 1 }}</span>
              <span style="padding-left: 20px">{{
                item.conditionContent
              }}</span>
            </p>
          </li>
          <li>
            <p class="tableTitle">
              <span>通知方式</span>
            </p>
            <p
              v-for="(item, index) in showNoticeList"
              :key="item.id"
              class="tableList"
            >
              <span class="tableItem">通知{{ index + 1 }}</span>
              <span style="padding: 0 20px">{{ item.name }}</span>
              <span>{{ item.value }}</span>
            </p>
          </li>
        </ul>
      </div>
    </div>
    <!-- 编辑规则弹窗 -->
    <a-modal
      :width="1100"
      title="编辑规则内容"
      :visible="rulesDialog"
      ok-text="确认"
      cancel-text="取消"
      @ok="onSubmit"
      @cancel="rulesDialog = false"
    >
      <a-form-model
        ref="ruleForm"
        class="editDialog"
        :model="rulesForm"
        :rules="rules"
      >
        <a-form-model-item label="静默时间" prop="time">
          <span class="timeDes"
            >若已配置静默时间，则告警规则触发告警后，在设定的时间范围内将不再重复生成告警和通知。超出设定时间告警仍未恢复，则会再次触发告警。</span
          >
          <!-- <a-select v-model="rulesForm.time" placeholder="请选择静默时间">
                        <a-select-option value="300">
                            5分钟
                        </a-select-option>
                        <a-select-option value="600">
                            10分钟
                        </a-select-option>
                        <a-select-option value="900">
                            15分钟
                        </a-select-option>
                        <a-select-option value="1200">
                            20分钟
                        </a-select-option>
                        <a-select-option value="1800">
                            30分钟
                        </a-select-option>
                        <a-select-option value="3600">
                            1小时
                        </a-select-option>
                        <a-select-option value="7200">
                            2小时
                        </a-select-option>
                        <a-select-option value="14400">
                            4小时
                        </a-select-option>
                        <a-select-option value="21600">
                            6小时
                        </a-select-option>
                        <a-select-option value="43200">
                            12小时
                        </a-select-option>
                        <a-select-option value="86400">
                            24小时
                        </a-select-option>
                    </a-select> -->
          <a-input
            v-model="rulesForm.time"
            placeholder="请输入静默时间"
            addon-after="秒"
          />
        </a-form-model-item>
        <a-form-model-item label="通知方式" prop="notice">
          <!-- <span>发送到本组织机构管理员账号绑定的手机号</span> -->
          <a-checkbox-group v-model="rulesForm.notice" @change="noticeType">
            <a-checkbox value="alarm"> 告警中心 </a-checkbox>
            <p style="display: flex; margin: 20px 0; align-items: center">
              <a-checkbox value="sms"> 短信通知 </a-checkbox>
              <a-input
                v-model="rulesForm.sms.smsAddress"
                placeholder="请输入手机号"
              />
            </p>
            <p style="display: flex; margin-bottom: 20px; align-items: center">
              <a-checkbox value="email"> 邮件通知 </a-checkbox>
              <a-input
                v-model="rulesForm.email.emailAddress"
                placeholder="请输入邮箱地址"
              />
            </p>
            <p style="display: flex; align-items: center">
              <a-checkbox value="http"> 第三方推送 </a-checkbox>
              <a-input
                v-model="rulesForm.http.url"
                placeholder="请输入接口地址"
                style="width: 32%"
              />
              <a-input
                v-model="rulesForm.http.header.token"
                style="margin: 0 2%; width: 32%"
                placeholder="请输入请求头"
              />
              <a-input
                v-model="rulesForm.http.method"
                placeholder="请输入请求方式"
                style="width: 32%"
              />
            </p>
          </a-checkbox-group>
        </a-form-model-item>
        <a-form-model-item label="执行限制" prop="perfectMatchMode">
          <a-radio-group v-model="rulesForm.perfectMatchMode">
            <a-radio value="0"> 满足任意条件执行 </a-radio>
            <a-radio value="1"> 满足全部条件执行 </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <div
          v-for="(item, index) in rulesForm.rules"
          :key="item.keyId"
          class="rulesWrap"
        >
          <ul>
            <li>
              <p class="page-title" style="font-size: 16px">
                <span>启动规则{{ index + 1 }}</span>
                <!-- <a-icon
                  type="close"
                  v-if="rulesForm.rules.length > 1"
                  class="delete-page"
                  @click="deletePage(item.keyId)"
                ></a-icon> -->
              </p>
              <a-form-model-item
                label="产品"
                :prop="`rules.${index}.product`"
                :rules="{
                  required: true,
                  message: '请选择产品',
                  trigger: 'change',
                }"
              >
                <a-select
                  v-model="item.product"
                  placeholder="请选择产品"
                  style="width: 200px"
                  @change="productChange(item)"
                >
                  <a-select-option
                    v-for="val in productList"
                    :key="val.id"
                    :value="val.code"
                  >
                    {{ val.productName }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </li>
            <li>
              <div style="display: flex; flex-wrap: wrap">
                <div
                  v-for="(node, count) in item.conditionContentView"
                  :key="count"
                  class="rulesList"
                >
                  <a-icon
                    type="close"
                    class="delete-card"
                    v-if="item.conditionContentView.length > 1"
                    @click="deleteCard(item.keyId, node.cardId)"
                  ></a-icon>
                  <a-form-model-item
                    label="触发方式"
                    :prop="`rules.${index}.conditionContentView.${count}.type`"
                    :rules="{
                      required: true,
                      message: '请选择触发方式',
                      trigger: 'change',
                    }"
                  >
                    <a-select
                      v-model="node.type"
                      placeholder="请选择触发方式"
                      style="width: 200px"
                    >
                      <a-select-option
                        v-for="type in item.triggerTypeList"
                        :key="type.name"
                        @click="node.fun = ''"
                        :value="type.name"
                      >
                        {{ type.label }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item
                    label="功能"
                    :prop="`rules.${index}.conditionContentView.${count}.fun`"
                    :rules="{
                      required: true,
                      message: '请选择功能',
                      trigger: 'change',
                    }"
                  >
                    <div style="display: flex">
                      <a-select
                        v-model="node.fun"
                        placeholder="请选择功能"
                        style="width: 200px"
                      >
                        <template v-for="type in item.triggerTypeList">
                          <template
                            v-if="
                              type.name === 'properties' &&
                                node.type === 'properties'
                            "
                          >
                            <a-select-option
                              v-for="fun in type.value"
                              :key="fun.value"
                              :value="fun.value"
                            >
                              {{ fun.label }}
                            </a-select-option>
                          </template>
                          <template
                            v-if="
                              type.name === 'events' && node.type === 'events'
                            "
                          >
                            <a-select-option
                              v-for="fun in type.value"
                              :key="fun.value"
                              :value="fun.value"
                            >
                              {{ fun.label }}
                            </a-select-option>
                          </template>
                          <template
                            v-if="
                              type.name === 'services' &&
                                node.type === 'services'
                            "
                          >
                            <a-select-option
                              v-for="fun in type.value"
                              :key="fun.value"
                              :value="fun.value"
                            >
                              {{ fun.label }}
                            </a-select-option>
                          </template>
                        </template>
                      </a-select>
                      <a-select
                        v-model="node.con"
                        placeholder="组装方式"
                        style="width: 120px; margin: 0 30px 0 120px"
                        @change="
                          onConnect(node.isStyle, index, count, node.con)
                        "
                      >
                        <a-select-option value="and"> and </a-select-option>
                        <a-select-option value="or"> or </a-select-option>
                      </a-select>
                    </div>
                  </a-form-model-item>
                  <div style="display: flex">
                    <a-form-model-item
                      label="判断条件"
                      :prop="
                        `rules.${index}.conditionContentView.${count}.condition`
                      "
                      :rules="{
                        required: true,
                        message: '请选择判断条件',
                        trigger: 'change',
                      }"
                    >
                      <a-select
                        v-model="node.condition"
                        placeholder="请选择"
                        style="width: 140px; margin-right: 20px"
                      >
                        <a-select-option value=">"> &gt; </a-select-option>
                        <a-select-option value="<"> &lt; </a-select-option>
                        <a-select-option value="<="> &le; </a-select-option>
                        <a-select-option value=">="> &ge; </a-select-option>
                        <a-select-option value="=="> = </a-select-option>
                        <a-select-option value="!="> != </a-select-option>
                      </a-select>
                    </a-form-model-item>
                    <a-form-model-item
                      label="预设值"
                      :prop="`rules.${index}.conditionContentView.${count}.val`"
                      :rules="{
                        required: true,
                        message: '请选择预设值',
                        trigger: 'change',
                      }"
                    >
                      <a-input
                        v-model="node.val"
                        placeholder="请输入预设值"
                        style="width: 120px"
                      />
                    </a-form-model-item>
                  </div>
                </div>
              </div>
              <p>if "{{ item.expression }}"</p>
            </li>
          </ul>
        </div>
        <a-button @click="addRules">
          <a-icon type="plus" />
          增加启动规则
        </a-button>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import {
  getWarningRulesDetails,
  editRules,
  getRulesContent,
  editRulesContent,
} from "@/api/warningCenter";
import { validateNum } from "@/utils/validate";
import { productList, productDetail } from "@/api/product";
export default {
  name: "EditRules",
  data() {
    return {
      // 当前告警规则id
      id: "",
      ruleId: "",
      ruleDetails: {},
      // 当前规则是否为编辑状态
      visible: true,
      // 编辑规则弹窗是否显示
      rulesDialog: false,
      // 当前规则编辑表单数据
      form: {},
      // 编辑规则内容表单数据
      rulesForm: {
        time: "",
        notice: [],
        sms: {
          smsAddress: "",
          templateCode: "",
        },
        email: {
          emailAddress: "",
          templateCode: "",
        },
        http: {
          url: "",
          header: {
            token: "",
          },
          method: "",
        },
        perfectMatchMode: "",
        rules: [
          {
            keyId: 1,
            product: "",
            triggerTypeList: [],
            judgeCode: "",
            conditionType: "expression",
            expression: "",
            conditionContentView: [
              {
                cardId: 1,
                type: "",
                fun: "",
                condition: "",
                val: "",
                con: undefined,
                isStyle: true,
              },
            ],
          },
        ],
      },
      // 编辑规则内容弹窗中的产品列表数据
      productList: [],
      // 编辑规则内容表单验证规则
      rules: {
        time: [
          { required: true, message: "请输入静默时间", trigger: "blur" },
          { validator: validateNum, trigger: "blur" },
        ],
        // style: [
        //   {
        //     required: true,
        //     message: "请选择触发方式",
        //     trigger: "change",
        //   },
        // ],
        notice: [
          { required: true, message: "请选择通知方式", trigger: "change" },
        ],
        perfectMatchMode: [
          { required: true, message: "请选择执行限制", trigger: "change" },
        ],
        // product: [{ required: true, message: "请选择产品", trigger: "change" }],
        // fun: [{ required: true, message: "请选择功能", trigger: "change" }],
      },
      ruleInfo: {},
    };
  },
  created() {
    // console.log(this.$route.query);
    this.id = this.$route.query.id;
    if (this.$route.query.ruleId) this.ruleId = this.$route.query.ruleId;
    this.getRulesDetails();
    if (this.ruleId) this.getRulesData();
    productList()
      .then((res) => {
        this.productList = res.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  computed: {
    //修改规则所需参数
    getParams() {
      const params = {
        id: this.ruleId,
        perfectMatchMode: this.rulesForm.perfectMatchMode,
        silencePeriod: this.rulesForm.time,
        alarmRuleInfoId: this.id,
        actionReqs: [],
        conditionReqs: [],
      };
      params.actionReqs = this.rulesForm.notice.map((item, index) => {
        const action = {
          actionTypeName: item,
          orderIndex: index + 1,
        };
        if (item === "email") {
          const email = {
            emailAddress: this.rulesForm.email.emailAddress.split(","),
            templateCode: this.rulesForm.email.templateCode,
          };
          action.param = JSON.stringify(email);
        } else if (item === "sms") {
          const sms = {
            smsAddress: this.rulesForm.sms.smsAddress.split(","),
            templateCode: this.rulesForm.sms.templateCode,
          };
          action.param = JSON.stringify(sms);
        } else if (item === "http") {
          action.param = JSON.stringify(this.rulesForm.http);
        } else if (item === "alarm") {
          action.param = JSON.stringify({});
        }
        return action;
      });
      params.conditionReqs = this.rulesForm.rules.map((item) => {
        const condition = {
          productCode: item.product,
          conditionType: item.conditionType,
          conditionContent: item.expression,
          conditionContentView: "",
        };
        return condition;
      });
      return params;
    },
    //规则内容通知方式数据获取
    showNoticeList() {
      return this.rulesForm.notice.map((item, index) => {
        const action = {
          name: item,
          value: "",
          id: index + 1,
        };
        if (item === "email") {
          action.value = this.rulesForm.email.emailAddress
        } else if (item === "sms") {
          action.value = this.rulesForm.sms.smsAddress
        } else if (item === "http") {
          action.value = this.rulesForm.http.url;
        }
        return action;
      });
    },
  },
  watch: {
    rulesForm: {
      handler() {
        this.rulesForm.rules.forEach((item) => {
          let expression = "";
          item.conditionContentView.forEach((_item, _index) => {
            if (_index !== 0) {
              expression += ` ${item.judgeCode} `;
            }
            expression += `${_item.type}.${_item.fun}${_item.condition}${_item.val}`;
          });
          item.expression = expression;
        });
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    // 获取告警规则详情
    getRulesDetails() {
      getWarningRulesDetails(this.id)
        .then((res) => {
          let info = res.data;
          this.ruleDetails = info;
          this.form = {
            name: info.alarmRuleName,
            level: info.level.toString(),
            desc: info.remark,
          };
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 确认编辑操作
    onSure() {
      let info = this.form;
      editRules(info.name, info.level, info.desc, 1, this.id)
        .then(() => {
          this.getRulesDetails();
          this.$message.success("编辑成功！");
          this.visible = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 获取规则内容数据
    getRulesData() {
      getRulesContent(this.ruleId)
        .then((res) => {
          // console.log(res);
          let info = JSON.parse(JSON.stringify(res.data));
          let resRules = JSON.parse(JSON.stringify(this.rulesForm));
          //静默时间
          resRules.time = info.silencePeriod;
          //处理通知方式
          resRules.notice = info.actions.map((item) => {
            // console.log(JSON.parse(item.param))
            if (item.actionTypeName === "email") {
              resRules.email.emailAddress = JSON.parse(
                item.param
              ).emailAddress.join(",");
              resRules.email.templateCode = JSON.parse(item.param).templateCode;
            } else if (item.actionTypeName === "http") {
              resRules.http.url = JSON.parse(item.param).url;
              resRules.http.header = JSON.parse(item.param).header;
              resRules.http.method = JSON.parse(item.param).method;
            } else if (item.actionTypeName === "sms") {
              resRules.sms.smsAddress = JSON.parse(item.param).smsAddress.join(
                ","
              );
              resRules.sms.templateCode = JSON.parse(item.param).templateCode;
            } else if (item.actionTypeName === "alarm") {
              resRules.notice.push("alarm");
            }
            return item.actionTypeName;
          });
          /*  处理规则集合  */
          //执行限制
          if (info.conditions.length > 0) {
            resRules.rules.length = 0;
            // console.log("resRules", resRules);
            info.conditions.forEach(async (item, index) => {
              const ruleObj = {
                keyId: index + 1,
              };
              //获取条件类型
              ruleObj.conditionType = item.conditionType;
              //获取产品名称
              const productListRes = await productList();
              const product = productListRes.data.find(
                (product) => product.code === item.productCode
              );
              ruleObj.product = product.code;
              const productDataRes = await productDetail(product.id);
              const productData = JSON.parse(productDataRes.data.metaData);
              // console.log('productData', productData)
              //处理触发方式列表
              ruleObj.triggerTypeList = this.dealTriggerTypeList(productData);
              //将条件表达式转化为数组用于展示
              const { ruleArr, judgeCode } = this.stringToArray(
                item.conditionContent
              );
              ruleObj.judgeCode = judgeCode;
              ruleObj.conditionContentView = ruleArr.map((rule, ruleIndex) => {
                return {
                  cardId: ruleIndex + 1,
                  type: rule.trigger,
                  fun: rule.type,
                  condition: rule.symbol,
                  val: rule.value,
                  con: rule.logic,
                  isStyle: false,
                };
              });
              //最后一条规则的逻辑符号置为undfind
              ruleObj.conditionContentView[
                ruleObj.conditionContentView.length - 1
              ].con = undefined;
              ruleObj.conditionContentView[
                ruleObj.conditionContentView.length - 1
              ].isStyle = true;
              resRules.rules.push(ruleObj);
            });
          }
          resRules.perfectMatchMode = info.perfectMatchMode.toString();
          this.rulesForm = Object.assign(this.rulesForm, resRules);
          // console.log("ruleForm", this.rulesForm);
          this.ruleInfo = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //将条件表达式转化为数组用于展示
    stringToArray(data) {
      let ruleArr = [];
      let judgeCode;
      // console.log(data)
      if (data.indexOf("and") !== -1) {
        ruleArr = this.dealLogic("and", data);
        judgeCode = "and";
      } else if (data.indexOf("or") !== -1) {
        ruleArr = this.dealLogic("or", data);
        judgeCode = "or";
      } else {
        const symbolArr = [">", "<", "<=", ">=", "==", "!="];
        const symbol = symbolArr.find((symbol) => data.indexOf(symbol) !== -1);
        ruleArr.push({
          trigger: data.split(symbol)[0].split(".")[0],
          type: data.split(symbol)[0].split(".")[1],
          value: data.split(symbol)[1],
          symbol,
          logic: undefined,
        });
      }
      return { ruleArr, judgeCode };
    },
    //根据逻辑符号处理条件表达式
    dealLogic(logic, data) {
      let ruleArr = [];
      let arr1 = data.split(logic);
      const symbolArr = [">", "<", "<=", ">=", "==", "!="];
      //去除空格
      ruleArr = arr1.map((item) => {
        item = item.replace(/(^\s*)|(\s*$)/g, "");
        const symbol = symbolArr.find((symbol) => item.indexOf(symbol) !== -1);
        return {
          trigger: item.split(symbol)[0].split(".")[0],
          type: item.split(symbol)[0].split(".")[1],
          value: item.split(symbol)[1],
          symbol,
          logic,
        };
      });
      return ruleArr;
    },
    //处理触发方式列表的方法
    dealTriggerTypeList(productData) {
      const triggerTypeList = [];
      if (productData.events.length > 0) {
        const event = productData.events.map((item) => {
          return {
            label: item.name,
            value: item.identifier,
          };
        });
        triggerTypeList.push({
          label: "设备事件触发",
          name: "events",
          value: event,
        });
      }
      if (productData.properties.length > 0) {
        const properties = productData.properties.map((item) => {
          return {
            label: item.name,
            value: item.identifier,
          };
        });
        triggerTypeList.push({
          label: "设备数据触发",
          name: "properties",
          value: properties,
        });
      }
      if (productData.services.length > 0) {
        const service = productData.services.map((item) => {
          return {
            label: item.name,
            value: item.identifier,
          };
        });
        triggerTypeList.push({
          label: "设备状态触发",
          name: "services",
          value: service,
        });
      }
      return triggerTypeList;
    },
    // 点击编辑规则弹窗中的增加启动规则按钮
    addRules() {
      this.rulesForm.rules.push({
        keyId: 1,
        product: "",
        triggerTypeList: [],
        judgeCode: "",
        conditionType: "expression",
        expression: "",
        conditionContentView: [
          {
            product: "",
            type: "",
            fun: "",
            condition: "",
            val: "",
            con: undefined,
            isStyle: true,
          },
        ],
      });
      this.rulesForm.rules.forEach((item, index) => {
        item.keyId = index + 1;
      });
      // console.log(this.rulesForm.rules);
    },
    //删除告警规则
    deletePage(id) {
      const index = this.rulesForm.rules.findIndex((item) => item.keyId === id);
      if (index !== -1) this.rulesForm.rules.splice(index, 1);
    },
    //删除条件
    deleteCard(pageId, cardId) {
      console.log(pageId, cardId);
      const indexPage = this.rulesForm.rules.findIndex(
        (item) => item.keyId === pageId
      );
      const indexCard = this.rulesForm.rules[
        indexPage
      ].conditionContentView.findIndex((item) => item.cardId === cardId);
      this.rulesForm.rules[indexPage].conditionContentView.splice(indexCard, 1);
      let length = this.rulesForm.rules[indexPage].conditionContentView.length;
      if (length === 1) this.rulesForm.rules[indexPage].judgeCode = undefined;
      this.rulesForm.rules[indexPage].conditionContentView.forEach(
        (item, index) => {
          if ((index = length - 1)) {
            item.isStyle = true;
            item.con = undefined;
          }
        }
      );
    },
    // 选择编辑规则内容弹窗中的通知方式
    noticeType(val) {
      console.log(val);
    },
    //产品改变回调
    async productChange(data) {
      //   console.log(data);
      const product = this.productList.find(
        (product) => data.product === product.code
      );
      //   console.log(this.productList, product);
      const productDataRes = await productDetail(product.id);
      //   console.log(productDataRes);
      if (productDataRes.data.metaData) {
        const productData = JSON.parse(productDataRes.data.metaData);
        // console.log(productData);
        //处理触发方式列表
        data.triggerTypeList = this.dealTriggerTypeList(productData);
      }
    },
    onConnect(bool, num, index, val) {
      if (val !== this.rulesForm.rules[num].judgeCode) {
        this.rulesForm.rules[num].judgeCode = val;
        this.rulesForm.rules[num].conditionContentView.forEach((item) => {
          if (item.con) item.con = this.rulesForm.rules[num].judgeCode;
        });
      }
      if (bool) {
        this.rulesForm.rules[num].conditionContentView.push({
          cardId: "",
          product: "",
          type: "",
          fun: "",
          condition: "",
          val: "",
          con: undefined,
          isStyle: true,
        });
        this.rulesForm.rules[num].conditionContentView.forEach(
          (item, index) => {
            item.cardId = index + 1;
          }
        );
        // console.log(this.rulesForm.rules[num].conditionContentView);
        this.rulesForm.rules[num].conditionContentView[index].isStyle = false;
      }
    },
    // 编辑规则确认操作
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        // console.log("表单数据", this.getParams);
        if (valid) {
          editRulesContent(this.getParams).then((res) => {
            console.log(res);
          });
          this.$message.success("编辑成功！");
          this.rulesDialog = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.detailsWrap {
  margin: 30px;
  a {
    color: #333;
  }
  .detailsTitle {
    font-size: 20px;
    font-weight: bold;
    margin-right: 20px;
  }
  .detailsList {
    margin: 40px 40px 0;
    ul {
      display: flex;
      justify-content: space-between;
      li {
        margin-bottom: 12px;
      }
    }
  }
  .rulesDes {
    display: flex;
    align-items: center;
    margin: 0 40px;
  }
  .rulesBtn {
    text-align: right;
    margin: 20px 40px;
  }
  .rulesTitle {
    display: inline-block;
    width: 120px;
  }
  .rulesCon {
    display: flex;
    margin: 30px 40px 20px 0;
    justify-content: space-between;
  }
  .tableTitle {
    border-bottom: 1px solid #333;
    padding: 0 20px;
    margin: 0;
    background: #ccc;
    line-height: 40px;
  }
  .tableList {
    margin: 0;
    line-height: 50px;
  }
  .tableItem {
    display: inline-block;
    width: 180px;
    text-align: center;
    border-right: 1px solid #333;
  }
}
.timeDes {
  position: absolute;
  top: -50px;
  left: 80px;
  color: #999;
}
.editDialog {
  ::v-deep .ant-checkbox-group {
    width: 100%;
  }
  ::v-deep .ant-form-item label {
    width: 200px;
  }
  .rulesWrap {
    border: 1px solid #ccc;
    padding: 20px 20px 0;
    margin-bottom: 30px;
  }
  .rulesList {
    position: relative;
    border-bottom: 1px solid #ccc;
    margin: 0 20px 20px 0;
    .delete-card {
      position: absolute;
      top: 20px;
      right: 30px;
      z-index: 9999;
    }
    .delete-card:hover {
      color: #f60;
      cursor: pointer;
    }
  }
  .page-title {
    display: flex;
    justify-content: space-between;
    .delete-page:hover {
      color: #f60;
      cursor: pointer;
    }
  }
}
</style>
