import request from "@/api/server/index"

// 告警数据统计
export function getWarningStatistic() {
    return request({
        url: '/manager/alarm/record/alarmCount',
        method: 'get'
    })
}

// 告警列表
/*
    param num - 当前页码
    param count - 每页显示数据条数
    param start - 告警开始时间
    param end - 告警结束时间
    param sign - 告警状态
    param val - 告警级别
    param start1 - 处理开始时间
    param end1 - 处理结束时间
*/
export function getWarningList(num, count, start, end, sign, val, start1, end1) {
    return request({
        url: '/manager/alarm/record/page',
        method: 'post',
        data: {
            page: num,
            pageSize: count,
            alarmStartTime: start,
            alarmEndTime: end,
            status: sign,
            level: val,
            handleStartTime: start1,
            handleEndTime: end1
        }
    })
}

// 告警规则列表
/*
    param num - 当前页码
    param count - 每页显示数据条数
    param val - 需要查询的关键字
*/
export function getWarningRules(num, count, val) {
    return request({
        url: '/manager/alarm/ruleInfo/page',
        method: 'post',
        data: {
            page: num,
            pageSize: count,
            keyword: val
        }
    })
}
// 通过id查询告警规则详情
/*
    param id - 当前需要查询的告警规则id
*/
export function getWarningRulesDetails(id) {
    return request({
        url: `/manager/alarm/ruleInfo/get/${id}`,
        method: 'get'
    })
}

// 新建/编辑告警规则
/*
    param name - 告警规则名称
    param num - 告警级别
    param desc - 告警规则描述
    param style - 告警类型
    param val - 告警id
*/
export function editRules(name, num, desc, style, val) {
    return request({
        url: '/manager/alarm/ruleInfo/saveOrUpdate',
        method: 'post',
        data: {
            alarmRuleName: name,
            level: num,
            remark: desc,
            type: style,
            id: val
        }
    })
}

// 通过id删除告警规则
/*
    param id - 当前需要删除的告警规则id
*/
export function deleteWarningRules(id) {
    return request({
        url: `/manager/alarm/ruleInfo/remove/${id}`,
        method: 'post'
    })
}

// 通过id获取规则内容
/*
    param id - 当前需要查询规则内容的id
*/
export function getRulesContent(id) {
    return request({
        url: `/manager/rule/configure/get/${id}`,
        method: 'get'
    })
}

// 通过id编辑规则内容
/*
    param arr1 - 通知方式集合
    param arr2 - 条件集合
    param num1 - 告警规则id
    param num2 - 当前需要编辑规则内容的id
    param num3 - 条件匹配模式：(0:满足任意条件执行，1:满足所有条件)
    param num4 - 规则静默时长(单位：秒)
*/
export function editRulesContent(data) {
    return request({
        url: "/manager/rule/configure/saveOrUpdate",
        method: 'post',
        data
    })
}

// 规则实例列表
/*
    param num - 当前页码
    param count - 每页显示数据条数
    param val - 需要查询的关键字
*/
export function getWarningCase(data) {
    return request({
        url: '/manager/alarm/ruleInstance/page',
        method: 'post',
        data
    })
}

// 新增/编辑规则实例
/*
    param id - 当前页码
    param count - 每页显示数据条数
    param val - 需要查询的关键字
*/
export function editRuleCase(data) {
    return request({
        url: '/manager/alarm/ruleInstance/saveOrUpdate',
        method: 'post',
        data
    })
}

//根据id删除实例
export function deleteRuleInstanceById(id) {
    return request({
        url: `/manager/alarm/ruleInstance/remove/${id}`,
        method: 'post',
    })
}
//设置规则状态
export function setInstanceStatus(ruleInstanceId, status) {
    return request({
        url: `/manager/alarm/ruleInstance/status/${ruleInstanceId}/${status}`,
        method: 'get',
    })
}